import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormBuilder, FormControl, FormsModule, Validators } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { TranslocoDirective, TranslocoService } from "@ngneat/transloco";
import { AppStore } from "app/app-store.service";
import { BaseHttpComponent } from "app/core/components/base-http/base-http.component";
import { ValidationMessageModule } from "app/core/modules/validation-message/validation-message.module";
import { Brain } from "app/pages/dashboard/dashboard.model";
import { EmbeddingProjectsService } from "app/services/embedding-projects.service";
import { mergeMap, of } from "rxjs";

@Component({
  selector: "app-brain-name-dialog",
  standalone: true,
  // eslint-disable-next-line max-len
  imports: [
    CommonModule,
    TranslocoDirective,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ValidationMessageModule,
    MatButtonModule,
    MatDialogModule,
  ],
  templateUrl: "./brain-name-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrainNameDialogComponent extends BaseHttpComponent {
  brainDetailForm;
  overriteMessageError;
  brain?: Brain | null;
  constructor(
    private fb: FormBuilder,
    private translocoService: TranslocoService,
    private dialogRef: MatDialogRef<BrainNameDialogComponent>,
    private embeddingProjectService: EmbeddingProjectsService,
  ) {
    super();
    this.brainDetailForm = this.fb.group({
      brainName: new FormControl("", [Validators.required, Validators.maxLength(256), Validators.minLength(1)]),
    });
    this.subs$.add(AppStore.selectedBrain$.subscribe((brain) => {
      this.brain = brain;
    }));

    this.overriteMessageError = { required: this.translocoService.translate("addBrainName.errorMessage") };
  }

  onClick() {
    if (this.brainDetailForm.valid && this.brain) {
      const brainName= this.brainDetailForm.get("brainName")?.value;

      if(brainName) {
        this.embeddingProjectService.get(this.brain.id)
          .pipe(
            mergeMap((result) => {
              if (result && result.isSuccess && result.data) {
                const brain = result.data;
                brain.projectName = brainName;
                return this.embeddingProjectService.update(brain);
              } else {
                return of(null);
              }
            })
          )
          .subscribe((resp) => {
            if (resp && resp.isSuccess && resp.data) {
              this.brain = resp.data;
              const brains = AppStore.brainProjects$.value;
              brains.map((x) => {
                if (x.id === resp.data?.id) {
                  x.projectName = resp.data.projectName;
                  return x;
                } else {
                  return x;
                }
              });
              AppStore.brainProjects$.next(brains);
              AppStore.selectedBrainId$.next(resp.data?.id);
              AppStore.selectedBrain$.next(resp.data);
              this.cdr.markForCheck();
              this.dialogRef.close(true);
            }
          });
      }
    }
  }
}
