import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { BrainSettings } from "app/core/models/brain-settings.model";

@Injectable({
  providedIn: "root",
})
export class BrainSettingsService {
  private settingsSubject = new BehaviorSubject<BrainSettings | null>(null);
  settings$ = this.settingsSubject.asObservable();

  updateSettings(settings: BrainSettings): void {
    this.settingsSubject.next(settings);
  }
}
