import { MatPaginator } from "@angular/material/paginator";
import { TranslocoService } from "@ngneat/transloco";
import { AppStore } from "app/app-store.service";
import { AFFILIATE_LINK, DASHBOARD_PAGE_SIZE, EMBEDDING_FILE_ICON_MAP, LS_TOKEN, ROLES } from "app/const/app-constant";
import { environment } from "app/environments/environment";
import { MyBrainPlans, UserData } from "app/pages/home/home.model";
import {
  getLocalStorageInterface,
  isLocalStorageInterfaceDefined,
} from "../modules/mobile-interfaces/app-local-storage-interface";

export function isMobileDevice() {
  return !!navigator.userAgent.match(/android|iphone|ipad|iPod/i);
}

export function isApple() {
  return !!navigator.userAgent.match(/iphone|ipad|iPod/i);
}

export function setTokenAndUserData() {
  const token = localStorage.getItem(LS_TOKEN);
  if (token) {
    AppStore.isLoggedIn$.next(true);
  }
}

export function replaceParamsWithValue(url: string, params: Record<string, string>) {
  Object.keys(params).forEach((param) => {
    url = url.replace(`:${param}`, params[param]);
  });
  return url;
}

export function generateRandomString(length: number, withNumbers = false) {
  let result = "";
  let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

  if (withNumbers) {
    characters += "0123456789";
  }
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function removeTokenForLogout() {
  localStorage.removeItem(LS_TOKEN);
  localStorage.removeItem(DASHBOARD_PAGE_SIZE);
  AppStore.isLoggedIn$.next(false);
  AppStore.userData$.next(null);
  AppStore.userDataLimits$.next(null);
  if (isLocalStorageInterfaceDefined()) {
    getLocalStorageInterface().onLogout();
  }
}

export function isBrowserChrome(): boolean {
  const userAgent = navigator.userAgent.toLowerCase();
  const isChrome =
    userAgent?.indexOf("chrome") > -1 &&
    userAgent?.indexOf("edg") === -1 && // Exclude Edge
    userAgent?.indexOf("chromium") === -1 && // Exclude Chromium
    userAgent?.indexOf("opr") === -1; // Exclude Opera
  return isChrome;
}

export function dateDifferenceInSec(dateComparing: string, currentDate: Date = new Date()) {
  const formattedDate = new Date(dateComparing);
  const dateDifference: number = currentDate.getTime() - formattedDate.getTime();
  return dateDifference / 1000;
}

export function getFormattedBrainName(query: string): string {
  const withoutExtension = query.trim().replace(/\.[a-zA-Z0-9]+$/, "");
  if (withoutExtension.length <= 50) {
    return withoutExtension;
  } else {
    return withoutExtension.substring(0, 50) + "...";
  }
}

export function translatePaginationBar(transloco: TranslocoService, itemsPerPageKey: string, paginator: MatPaginator) {
  return transloco.selectTranslateObject("pagination", {}, transloco.getActiveLang()).subscribe((translations) => {
    paginator._intl.itemsPerPageLabel = translations[itemsPerPageKey];
    paginator._intl.firstPageLabel = translations["firstPageLabel"];
    paginator._intl.lastPageLabel = translations["lastPageLabel"];
    paginator._intl.nextPageLabel = translations["nextPageLabel"];
    paginator._intl.previousPageLabel = translations["previousPageLabel"];
    paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0) {
        // Handle the "no data" case
        return transloco.translate("pagination.XoutOfY", {
          x: 1,
          y: 1,
        });
      }
      const amountPages = Math.ceil(length / pageSize);
      return transloco.translate("pagination.XoutOfY", {
        x: page + 1,
        y: amountPages,
      });
    };
    paginator._intl.changes.next();
  });
}

export function redirectToAffiliates() {
  window.open(AFFILIATE_LINK, "_blank");
}

export function addAlpha(color?: string, opacity?: number): string {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

export function getCurrentYear(): number {
  return new Date().getFullYear();
}

export function getFileIconPath(typeOrExt: string | undefined): string {
  const type = typeOrExt?.replace(".", "").toLowerCase();
  const iconName = (type && EMBEDDING_FILE_ICON_MAP[type]) || "file-default.svg";
  return `./assets/images/icons/brain-content/${iconName}`;
}

export function toUrlSafeBase64(base64String: string): string {
  return base64String.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

export function fromUrlSafeBase64(urlSafeBase64: string): string {
  let base64String = urlSafeBase64.replace(/-/g, "+").replace(/_/g, "/");

  switch (base64String.length % 4) {
    case 2:
      base64String += "==";
      break;
    case 3:
      base64String += "=";
      break;
  }

  return base64String;
}

export function isRegularUser(user: UserData | undefined | null) {
  return (
    user?.planLevel == MyBrainPlans.free ||
    user?.planLevel == MyBrainPlans.enthusiast ||
    user?.planLevel.includes(MyBrainPlans.enthusiast)
  );
}

export function decodeBase64(base64String: string): string {
  return decodeURIComponent(
    atob(base64String)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join(""),
  );
}

// Function to decode the JWT payload
export function decodeJwtPayload(token: string): any {
  const payloadPart = token.split(".")[1];
  if (!payloadPart) {
    throw new Error("Invalid JWT format");
  }

  const decodedPayload = decodeBase64(payloadPart);
  return JSON.parse(decodedPayload);
}

// Function to check if user has admin role
export function isAdminUser() {
  const token = localStorage.getItem(LS_TOKEN);
  if (token) {
    const decoded: any = decodeJwtPayload(token);
    const roles = decoded[`${environment.audienceUrl}/oauth/api/roles`];
    if (roles && roles.includes(ROLES.ADMIN)) {
      return true;
    }
  }
  return false;
}

// Function to check if the JWT is valid (by checking its expiration)
export function isTokenValid(token: string): boolean {
  try {
    const decoded = decodeJwtPayload(token);

    if (!decoded.exp) {
      return false; // Invalid if there's no expiration claim
    }

    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    return decoded.exp > currentTime; // Check if token has expired
  } catch (error) {
    console.error("Invalid JWT token:", error);
    return false; // Token is invalid if there's an error in decoding
  }
}

export function generateGuid(): string {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (char) => {
    const random = (Math.random() * 16) | 0;
    const value = char === "x" ? random : (random & 0x3) | 0x8;
    return value.toString(16);
  });
}
