/* eslint-disable max-lines */
import { Clipboard } from "@angular/cdk/clipboard";
import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslocoModule, TranslocoPipe } from "@ngneat/transloco";
import { Conversation } from "app/api-models/conversation.models";
import { AppStore } from "app/app-store.service";
import { InternetSearchStatus, KeyValueBooleanObject } from "app/app.model";
import { CharacterFormatter } from "app/core/classes/character-formatter";
import { AskMyBrainSearchComponent } from "app/core/components/ask-my-brain-search/ask-my-brain-search.component";
import { BaseHttpComponent } from "app/core/components/base-http/base-http.component";
import { BrainStyle } from "app/core/components/embedded-chat/embedded-chat.model";
import { FollowUpQuestionComponent } from "app/core/components/follow-up-question/follow-up-question.component";
import { NoBrainComponent } from "app/core/components/no-brain/no-brain.component";
import { UserLimitComponent } from "app/core/components/user-limit/user-limit.component";
import { cleanupMarkdown } from "app/core/functions/cleanup-md";
import { addAlpha, fromUrlSafeBase64 } from "app/core/functions/helper-functions";
import { isAndroidApp } from "app/core/modules/mobile-interfaces/app-initialization-interface";
import { ProjectTypes } from "app/enums/project-types.enum";
import { environment } from "app/environments/environment";
import { HeaderComponent } from "app/layout/components/header/header.component";
import { Brain } from "app/pages/dashboard/dashboard.model";
import { UserDataLimits } from "app/pages/home/home.model";
import { ChatDataRole, SuggestedQuestionTranslation } from "app/pages/my-brain/my-brain.model";
import { PipesModule } from "app/pipes/pipes.module";
import { EmbeddingProjectsService } from "app/services/embedding-projects.service";
import { UserDataLimitsService } from "app/services/user-data-limits.service";
import { MarkdownModule } from "ngx-markdown";
import { concatMap, distinctUntilChanged, EMPTY, map, switchMap, timer } from "rxjs";
import {
  ConversationReferenceComponent
} from "app/core/components/conversation-reference/conversation-reference.component";

//TODO: Refactor this, chat-window and my-brain components into one
@Component({
  selector: "app-embedded-chat",
  standalone: true,
  imports: [
    CommonModule,
    FollowUpQuestionComponent,
    PipesModule,
    TranslocoPipe,
    TranslocoModule,
    AskMyBrainSearchComponent,
    MatSnackBarModule,
    HeaderComponent,
    UserLimitComponent,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    NoBrainComponent,
    MarkdownModule,
    ConversationReferenceComponent,
  ],
  templateUrl: "./embedded-chat.component.html",
  styleUrls: ["./embedded-chat.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
  encapsulation: ViewEncapsulation.None,
})
export class EmbeddedChatComponent extends BaseHttpComponent implements OnInit {
  protected readonly InternetSearchStatus = InternetSearchStatus;
  chats$ = AppStore.allChats$;
  parsedChats: (Conversation & { extraMetadata: any })[] = [];
  answer$ = AppStore.answer$;
  brain!: Brain | null;
  role = ChatDataRole;
  activeLang = "";
  brainKey!: string;
  chatAccordionOpen: KeyValueBooleanObject<number> = {};
  suggestedQuestions: string[] = [];
  initialSuggestedQuestions: string[] = [];
  rezolveSuggestedQuestions: string[] = ["What is Brain Assistant?", "What is a Brain?"];
  suggestedQuestionsAsked: string[] = [];
  internetSearchStatus = InternetSearchStatus.searching;
  followuppQuestions: string[] = [];
  private readonly formatter = new CharacterFormatter();
  @ViewChild("chatContainer") private chatContainer!: ElementRef;
  scrolledUp = false;
  brainStyle?: BrainStyle;
  isCustomStylingEnabled?: boolean;
  isHidden = false;
  embeddedChatCreator = "";
  isLoading = true;
  startPosition?: number;
  currentPosition = 0;
  userDataLimits?: UserDataLimits;
  public allowBrainLLMSelect = environment.featureFlags.allowBrainLLMSelect;

  constructor(
    private https: HttpClient,
    private element: ElementRef<HTMLDivElement>,
    private renderer: Renderer2,
    private clipboard: Clipboard,
    private embeddingProjectService: EmbeddingProjectsService,
    private userDataLimitsService: UserDataLimitsService,
  ) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.brainKey = encodeURIComponent(fromUrlSafeBase64(params["id"]));
      this.isLoading = true;
      this.loadData();
    });

    this.subs$.add(
      this.chats$.subscribe(async (chats) => {
        this.getBrainContent();
        this.parsedChats = chats.map((chat) => ({
          ...chat,
          extraMetadata: chat.metadata
            ? typeof chat.metadata === "object"
              ? chat.metadata
              : JSON.parse(chat.metadata)
            : null,
        }));
        setTimeout(() => {
          const ele = this.element.nativeElement.querySelector("#last-chat");
          if (ele) {
            ele.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 1000);
        this.cdr.markForCheck();
      }),
    );

    this.subs$.add(
      AppStore.selectedBrain$.subscribe((brain) => {
        if (brain) {
          this.getSuggestedQuestion();
        }
      }),
    );

    this.subs$.add(
      AppStore.cancelAnswerStream$
        .pipe(
          switchMap((cancel) => {
            if (cancel) {
              return EMPTY;
            }
            return AppStore.answerStream$.pipe(concatMap((char) => timer(this.getDelayTime()).pipe(map(() => char))));
          }),
        )
        .subscribe((char) => {
          if (char?.stop) {
            this.answer$.next("");
            this.formatter.reset();
          } else {
            AppStore.answer$.next(this.formatter.formatCharacter(char.char));
          }
        }),
    );

    this.answer$.subscribe(() => {
      try {
        if (this.chatContainer) {
          if (!this.scrolledUp) {
            this.renderer.setProperty(
              this.chatContainer.nativeElement,
              "scrollTop",
              this.chatContainer.nativeElement.scrollHeight,
            );
          }
        }
      } catch (err) {
        console.error(err);
      }
    });

    this.subs$.add(
      AppStore.chatApiInProgress$.subscribe(() => {
        setTimeout(() => {
          const ele = this.element.nativeElement.querySelector("#last-chat");
          if (ele) {
            ele.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }, 100);
      }),
    );

    this.subs$.add(
      AppStore.userData$.subscribe((userData) => {
        if (userData) {
          this.userDataLimitsService.checkUserLimit(userData);
        }
      }),
    );

    this.subs$.add(
      AppStore.userDataLimits$.subscribe((resp) => {
        if (resp) {
          this.userDataLimits = resp;
          this.isHidden = resp.isQuestionExceeded;
        }
      }),
    );

    this.subs$.add(
      AppStore.selectedLanguage$.pipe(distinctUntilChanged()).subscribe((lang) => {
        this.activeLang = lang;
        this.getSuggestedQuestion();
        this.cdr.markForCheck();
      }),
    );
  }
  loadData() {
    this.getBrainContent();
    setTimeout(() => {
      const ele = this.element.nativeElement.querySelector("#last-chat");
      if (ele) {
        ele.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }, 1000);
  }

  async deleteMessages() {
    AppStore.clearChat$.next(true);
    this.chats$.next([]);
  }

  askSuggestedQuestion(ques: string) {
    AppStore.askQuesFromSuggestedQuestion$.next(ques);
    this.selectRandomSuggestedQuestions(ques);
  }

  onScroll(event: any) {
    const element = event.target;
    const atBottom = Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < 1;
    this.startPosition = element.scrollTop;
    const scroll = element.scrollTop;
    this.scrolledUp = !atBottom;
    this.currentPosition = scroll;
    this.cdr.markForCheck();
  }

  scrollToBottom(): void {
    const scrollElement = this.chatContainer.nativeElement;
    scrollElement.scrollTop = this.currentPosition;
    scrollElement.scroll({
      top: scrollElement.scrollHeight,
      behavior: "smooth",
    });
    this.scrolledUp = true;
  }

  sendConfirmation(option: string) {
    AppStore.askQuesFromSuggestedQuestion$.next(option);
  }

  getBrainContent() {
    this.embeddingProjectService.getSharedBrainData(this.brainKey).subscribe((resp) => {
      if (resp && resp.isSuccess && resp.data) {
        this.brain = resp.data?.project;
        this.embeddedChatCreator = resp.data?.user.id;
        this.followuppQuestions = this.brain.followUpQuestions;
        this.suggestedQuestions = this.brain.suggestedQuestions;
        this.initialSuggestedQuestions = this.brain.suggestedQuestions;
        this.brainStyle = {
          PrimaryColor: this.brain.styleBackgroundPrimaryColor,
          SecondaryColor: this.brain.styleBackgroundSecondaryColor,
        } as BrainStyle;
        this.isCustomStylingEnabled = this.brain.enableCustomStyling;
        this.isLoading = false;
        AppStore.selectedBrain$.next(this.brain);
        AppStore.userData$.next(resp.data?.user);
        this.cdr.markForCheck();
      } else {
        this.brain = null;
        this.isLoading = false;
        this.cdr.markForCheck();
      }
    });
  }

  private getDelayTime(): number {
    const charCount = AppStore.answer$.value.length;
    if (charCount < 10) return 25;
    if (charCount < 100) return 20;
    if (charCount < 300) return 10;
    return 0;
  }

  getSuggestedQuestion() {
    if (this.suggestedQuestions && this.suggestedQuestions.length !== 0) {
      const requestBody = {
        q: this.suggestedQuestions,
        target: this.activeLang === "ko_ko" ? "ko" : this.activeLang,
      };

      if (this.compareSuggestedQuestions(this.initialSuggestedQuestions, this.rezolveSuggestedQuestions)) {
        const languageSuggestions: { [key: string]: string[] } = {
          en_gb: this.rezolveSuggestedQuestions,
          es_es: ["¿Qué es Brain Assistant?", "¿Qué es un Brain?"],
          de_de: ["Was ist Brain Assistant?", "Was ist ein Brain?"],
          fr_fr: ["Qu'est-ce que Brain Assistant?", "Qu'est-ce qu'un Brain ?"],
          ar_ar: ["ما هو Brain Assistant؟", "ما هو Brain؟"],
          ko_ko: ["Brain Assistant란 무엇인가요?", "Brain란 무엇일까?"],
          zh_cn: ["什么是Brain Assistant?", "什么是Brain?"],
        };
        this.suggestedQuestions = languageSuggestions[this.activeLang] || this.suggestedQuestions;
      } else {
        this.https
          .post<SuggestedQuestionTranslation>(
            "https://translation.googleapis.com/language/translate/v2?key=AIzaSyDIXTrZe71kFrqPqw-IcUBJ95ffdyVKUoc",
            requestBody,
          )
          .subscribe((response) => {
            if (response && response.data && response.data.translations) {
              const translations = response.data.translations;
              this.suggestedQuestions = translations.map((translation) => {
                let translatedText = translation.translatedText;
                if (this.activeLang === "es_es") {
                  translatedText = translation.translatedText.replace(/cerebro/g, "Brain");
                  translatedText = translatedText.replace(/Cerebral/g, "Brain");
                }
                return translatedText;
              });
              this.cdr.markForCheck();
            }
          });
      }
    }
  }

  copyToClipboard(text: string) {
    this.clipboard.copy(cleanupMarkdown(text));
    if (!isAndroidApp()) {
      // eslint-disable-next-line max-lines
      this.successMsg(this.transloco.translate("defaultFollowUpQuestion.successfulCopy"));
    }
  }

  selectRandomSuggestedQuestions(lastQuestionAsked: string) {
    if (!this.brain || this.brain.projectType !== ProjectTypes.ContentBrain) return;
    if (lastQuestionAsked) {
      this.suggestedQuestionsAsked.push(lastQuestionAsked);
    }
    if (this.compareSuggestedQuestions(this.initialSuggestedQuestions, this.rezolveSuggestedQuestions)) {
      return;
    }

    const newQuestions = (this.brain.suggestedQuestions || []).filter((q) => !this.suggestedQuestionsAsked.includes(q));
    const newQuestionsShuffled = newQuestions.sort(() => 0.5 - Math.random());

    const suggestedQuestionsAskedShuffled = this.suggestedQuestionsAsked.sort(() => 0.5 - Math.random());

    // Combine shuffled new and old questions
    const allQuestions = [...new Set([...newQuestionsShuffled, ...suggestedQuestionsAskedShuffled])];

    this.suggestedQuestions = allQuestions.slice(0, 3);
  }

  isTextTruncated(spanElement: HTMLSpanElement): boolean {
    if (this.pageWidthCheck()) {
      return false;
    }
    const element = spanElement;
    return element.scrollHeight > element.offsetHeight;
  }

  private pageWidthCheck(): boolean {
    const windowWidth = window.innerWidth;
    return windowWidth <= 1024;
  }

  private compareSuggestedQuestions(arr1: string[], arr2: string[]): boolean {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((value, index) => value === arr2[index]);
  }

  protected readonly addAlpha = addAlpha;
  protected readonly AppStore = AppStore;
  protected readonly ProjectTypes = ProjectTypes;
}
