/* eslint-disable max-lines */
import { AsyncPipe, NgClass, NgFor, NgIf, NgStyle, NgSwitchCase } from "@angular/common";
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { RouterLink, RouterOutlet } from "@angular/router";
import { TranslocoModule } from "@ngneat/transloco";
import { AppStore } from "app/app-store.service";
import {
  BrainContentType,
  CrawlWebSiteStatus,
  EmbeddingFile,
  EmbeddingFileStatus,
  StatusQueryType,
} from "app/app.model";
import { CONTENT_ROUTE_ID, DASHBOARD_ROUTE, SHARE_BRAIN_ROUTE_ID } from "app/const/app-constant";
import { AddMyBrainContentComponent } from "app/core/components/add-my-brain-content/add-my-brain-content.component";
import { BaseHttpComponent } from "app/core/components/base-http/base-http.component";
import { BrainNameDialogComponent } from "app/core/components/brain-name-dialog/brain-name-dialog.component";
import { ConfirmDialogComponent } from "app/core/components/confirm-dialog/confirm-dialog.component";
import { SimpleConfirmationPopupComponent } from "app/core/components/simple-confirmation-popup/simple-confirmation-popup.component";
import {
  getCurrentYear,
  isRegularUser,
  redirectToAffiliates,
  replaceParamsWithValue,
} from "app/core/functions/helper-functions";
import { BrainSettings } from "app/core/models/brain-settings.model";
import {
  getMobileappLeftSidebarInterface,
  isMobileInterfaceDefined,
} from "app/core/modules/mobile-interfaces/left-sidebar-interface";
import { ValidationMessageModule } from "app/core/modules/validation-message/validation-message.module";
import { BrainSettingsService } from "app/core/services/brain-settings.service";
import { Brain } from "app/pages/dashboard/dashboard.model";
import { MyBrainPlans, UserDataLimits } from "app/pages/home/home.model";
import { PipesModule } from "app/pipes/pipes.module";
import { EmbeddingFileService } from "app/services/embedding-files.service";
import { EmbeddingProjectsService } from "app/services/embedding-projects.service";
import { UsersService } from "app/services/users.service";
import { debounceTime, filter, mergeMap, of, takeUntil } from "rxjs";
import { LoaderService } from "app/core/components/loader/loader.service";
import { ScrollService } from "app/services/scroll.service";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
  selector: "app-left-sidebar",
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    MatDialogModule,
    AddMyBrainContentComponent,
    AsyncPipe,
    TranslocoModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    ConfirmDialogComponent,
    ValidationMessageModule,
    RouterLink,
    PipesModule,
    NgClass,
    NgSwitchCase,
    MatMenuModule,
    MatButtonModule,
    MatExpansionModule,
    MatSlideToggleModule,
    FormsModule,
    MatIconModule,
    RouterOutlet,
    NgStyle,
    MatTooltipModule,
  ],
  templateUrl: "./left-sidebar.component.html",
  styleUrls: ["./left-sidebar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftSidebarComponent extends BaseHttpComponent implements OnInit {
  brainContents: EmbeddingFile[] = [];
  processingBrainContents: EmbeddingFile[] = [];
  isProcessingBrainContents = false;
  selectedBrain?: Brain;
  displayBrainName = false;
  form;
  brainProjectName? = "";
  brainId = "";
  status = EmbeddingFileStatus;
  isNameAutomatic = false;
  userDataLimits!: UserDataLimits;
  sidebarActive = false;
  webCount = 0;
  fileCount = 0;
  userData = AppStore.userData$.value;
  loading = false;
  panelSettingsState = false;
  panelConfigState = false;
  settingsForm: FormGroup;
  private initialFormSet = false;
  refreshDataInProcess = false;
  @Input() isContentPage = false;
  @ViewChild("primaryColorInput") primaryColorInput!: ElementRef<HTMLInputElement>;
  @ViewChild("secondaryColorInput") secondaryColorInput!: ElementRef<HTMLInputElement>;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private embeddingProjectService: EmbeddingProjectsService,
    private usersService: UsersService,
    private brainSettingsService: BrainSettingsService,
    private embeddingFileService: EmbeddingFileService,
    private loaderService: LoaderService,
    private scrollService: ScrollService,
  ) {
    super();
    this.form = this.fb.nonNullable.group({
      brainName: ["", [Validators.required, Validators.maxLength(256), Validators.minLength(1)]],
    });
    this.settingsForm = this.fb.group({
      enableCustomStyling: [],
      settingShowSuggestions: [],
      settingFollowUps: [],
      settingShowReferences: [],
      styleBackgroundPrimaryColor: [],
      styleBackgroundSecondaryColor: [],
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(AddMyBrainContentComponent, {
      panelClass: "scrollable-dialog",
    });
    dialogRef.afterClosed().subscribe();
  }

  emitScroll(event: Event): void {
    this.scrollService.emitScrollEvent(event);
  }

  ngOnInit(): void {
    this.subs$.add(
      this.route.firstChild?.params.subscribe((params) => {
        this.brainId = params["id"];
        this.getEmbeddingProject(this.brainId);
      }),
    );
    this.subs$.add(
      AppStore.sidebarActive$.subscribe((status) => {
        this.sidebarActive = status;
        this.cdr.detectChanges();
      }),
    );

    this.subs$.add(
      AppStore.selectedBrain$.subscribe((brain) => {
        if (brain) {
          this.selectedBrain = brain || undefined;
          this.usersService.get().subscribe((resp) => {
            if (resp && resp.isSuccess && resp.data) {
              this.userData = resp.data;
            }
          });

          this.form.controls.brainName.setValue(this.selectedBrain?.projectName || "");

          const updatedBrain = {
            enableCustomStyling: !!this.selectedBrain?.enableCustomStyling,
            settingShowSuggestions: this.selectedBrain?.settingShowSuggestions || false,
            settingFollowUps: this.selectedBrain?.settingFollowUps || false,
            settingShowReferences: this.selectedBrain?.settingShowReferences || false,
            styleBackgroundPrimaryColor: this.selectedBrain?.styleBackgroundPrimaryColor || "#554176",
            styleBackgroundSecondaryColor: this.selectedBrain?.styleBackgroundSecondaryColor || "#EFF1F6",
          };
          this.settingsForm.patchValue({
            ...updatedBrain,
          });

          this.brainSettingsService.updateSettings(updatedBrain);

          this.initialFormSet = true;

          this.brainProjectName = this.selectedBrain?.projectName;
          this.isNameAutomatic = this.checkIsNameAutomatic(this.brainProjectName);
          if (this.selectedBrain?.bulkWebsiteCrawlJobStatus === CrawlWebSiteStatus.processing) {
            AppStore.isIndexing$.next(true);
            this.isProcessingBrainContents = true;
          }
          this.getEmbeddingFiles();
        }
        this.cdr.markForCheck();
      }),
    );

    this.subs$.add(
      AppStore.addedBrainContents$.subscribe((data) => {
        if (data && !this.refreshDataInProcess) {
          this.getEmbeddingFiles(false);
        }
      }),
    );

    this.subs$.add(
      AppStore.userDataLimits$.subscribe((resp) => {
        this.userDataLimits = resp as UserDataLimits;
      }),
    );

    Object.keys(this.settingsForm.controls).forEach((controlName) => {
      const control = this.settingsForm.get(controlName);

      if (control) {
        this.subs$.add(
          control.valueChanges
            .pipe(
              debounceTime(400),
              filter(() => this.initialFormSet),
              takeUntil(this.destroy$),
            )
            .subscribe(() => {
              const settings = this.settingsForm.value as BrainSettings;
              this.updateBrainSettings(settings);
              this.brainSettingsService.updateSettings(settings);
            }),
        );
      }
    });

    this.subs$.add(
      AppStore.userData$.subscribe((user) => {
        if (user) {
          this.userData = user;
          this.cdr.markForCheck();
        }
      }),
    );

    this.subs$.add(
      AppStore.brainContents$.subscribe((data) => {
        if (data) {
          const embeddingFiles = data;
          this.processingBrainContents = embeddingFiles.filter((x) => x.status === EmbeddingFileStatus.Processing);
          this.isProcessingBrainContents = embeddingFiles.some(
            (content) => content.status === EmbeddingFileStatus.Processing,
          );
          this.webCount = embeddingFiles.filter(
            (a) => a.contentType === BrainContentType.Link || a.contentType === BrainContentType.Website,
          ).length;
          this.fileCount = embeddingFiles.filter((a) => a.contentType === BrainContentType.File).length;

          this.brainContents = embeddingFiles;
          this.cdr.markForCheck();
        }
      }),
    );
  }

  getEmbeddingFiles(showLoader = true) {
    if (showLoader) {
      this.loaderService.showLoader("brain");
    }

    this.embeddingFileService.getBrainEmbeddingFiles(this.brainId, StatusQueryType.All).subscribe((resp) => {
      if (resp && resp.isSuccess && resp.data) {
        this.brainContents = resp.data?.results;
        AppStore.brainContents$.next(resp.data?.results);
      }
      if (showLoader) {
        this.loaderService.hideLoader();
      }
      this.cdr.detectChanges();
    });
  }

  refreshData() {
    if (this.refreshDataInProcess) {
      return;
    }

    this.refreshDataInProcess = true;
    this.cdr.detectChanges();

    let animationCompleted = false;
    let isResponseReceived = false;

    this.embeddingFileService.getBrainEmbeddingFiles(this.brainId, StatusQueryType.All).subscribe((resp) => {
      if (resp && resp.data) {
        this.brainContents = resp.data?.results;
        AppStore.addedBrainContents$.next(resp.data.results);
        AppStore.brainContents$.next(resp.data.results);
      }

      isResponseReceived = true;

      if (animationCompleted) {
        this.refreshDataInProcess = false;
        this.cdr.detectChanges();
      }
    });

    setTimeout(() => {
      animationCompleted = true;

      if (isResponseReceived) {
        this.refreshDataInProcess = false;
        this.cdr.detectChanges();
      }
    }, 650);
  }

  updateBrainSettings(settings: BrainSettings): void {
    if (this.selectedBrain && this.settingsForm.dirty) {
      this.embeddingProjectService
        .get(this.selectedBrain.id)
        .pipe(
          mergeMap((result) => {
            if (result.isSuccess && result.data) {
              const brain = result.data;
              this.selectedBrain = result.data;
              // Update the brain with new settings
              Object.assign(brain, settings);
              return this.embeddingProjectService.update(brain);
            } else {
              return of(null);
            }
          }),
        )
        .subscribe((resp) => {
          if (resp && resp.isSuccess && resp.data) {
            this.selectedBrain = resp.data;
            this.cdr.markForCheck();
          }
        });
    }
  }

  back() {
    const currentUrl = this.router.url;
    if (currentUrl.includes("/brain")) {
      this.router.navigate([DASHBOARD_ROUTE]);
    } else if (currentUrl.includes("/content")) {
      this.router.navigate(["brain", this.brainId]);
    } else if (currentUrl.includes("/share-brain")) {
      this.router.navigate(["brain", this.brainId]);
    }
    this.cdr.markForCheck();
  }

  editBrainName() {
    this.displayBrainName = true;
    this.form.controls.brainName.setValue(this.brainProjectName || "");
    this.cdr.markForCheck();
  }

  cancelEditName() {
    this.displayBrainName = false;
    this.cdr.markForCheck();
  }

  updateBrainName() {
    this.brainProjectName = this.form.get("brainName")?.value;
    this.displayBrainName = false;
    this.cdr.markForCheck();
    if (this.selectedBrain && this.form.valid) {
      const brain = this.selectedBrain;
      brain.projectName = this.form.controls.brainName.value;
      this.embeddingProjectService.update(brain).subscribe((resp) => {
        if (resp.isSuccess && resp.data) {
          this.selectedBrain = resp.data;
          AppStore.selectedBrain$.next(this.selectedBrain);
          this.brainProjectName = this.form.get("brainName")?.value;
          this.cdr.markForCheck();
        }
      });
    }
  }

  navigateToContent() {
    this.router.navigate([`${replaceParamsWithValue(CONTENT_ROUTE_ID, { id: this.brainId, tab: "waitlist" })}`]);
  }

  navigateToShare() {
    this.router.navigate([`${replaceParamsWithValue(SHARE_BRAIN_ROUTE_ID, { id: this.brainId })}`]);
  }

  backSave() {
    const dialogRef = this.dialog.open(BrainNameDialogComponent, {
      disableClose: true,
      panelClass: "scrollable-dialog",
    });
    dialogRef.afterClosed().subscribe(() => {
      AppStore.brainProjects$.subscribe((resp) => {
        this.brainProjectName = resp[0].projectName;
        this.cdr.markForCheck();
      });
      this.isNameAutomatic = false;
    });
  }

  backDelete() {
    const popupTranslations = {
      title: this.transloco.translate("leftSidebar.popupTitle"),
      message: this.transloco.translate("leftSidebar.popupMessage"),
      yesText: this.transloco.translate("leftSidebar.popupYes"),
      noText: this.transloco.translate("leftSidebar.popupNo"),
    };
    const params = {
      maxWidth: "420px",
      panelClass: "scrollable-dialog",
      height: "auto",
      disableClose: true,
      componentConfiguration: {
        title: popupTranslations.title,
        message: popupTranslations.message,
        yesText: popupTranslations.yesText,
        noText: popupTranslations.noText,
        showDeleteIcon: true,
      },
    };
    const matDialogConfigParams = Object.assign({}, params);
    const dialogRef = this.dialog.open(SimpleConfirmationPopupComponent, matDialogConfigParams);
    dialogRef.componentInstance.configuration = params.componentConfiguration;
    dialogRef.afterClosed().subscribe((resp) => {
      if (!resp) {
        this.embeddingProjectService.delete(this.brainId).subscribe((res) => {
          if (res) {
            AppStore.selectedBrainId$.next("");
            AppStore.selectedBrain$.next(null);
            AppStore.chatApiInProgress$.next({ brainId: this.brainId, inProgress: false });
            AppStore.brainProjects$.next([]);
            this.router.navigate(["dashboard"]);
            this.cdr.markForCheck();
          }
        });
      } else if (resp === "closed") {
        dialogRef.close();
      } else {
        const dialogRef = this.dialog.open(BrainNameDialogComponent, {
          disableClose: true,
          panelClass: "scrollable-dialog",
        });
        dialogRef.afterClosed().subscribe(() => {
          AppStore.brainProjects$.subscribe((resp) => {
            this.brainProjectName = resp[0].projectName;
            this.cdr.markForCheck();
          });
          this.isNameAutomatic = false;
          this.router.navigate(["dashboard"]);
        });
      }
    });
  }

  getEmbeddingProject(id: string) {
    this.embeddingProjectService.get(id).subscribe((resp) => {
      if (resp.isSuccess && resp.data) {
        this.selectedBrain = resp.data;
        this.brainProjectName = resp.data.projectName;
        this.cdr.markForCheck();
      }
    });
  }

  checkIsNameAutomatic(input: string | undefined): boolean {
    if (!input) {
      return true;
    }
    const pattern = /^Brain \d{1,2}:\d{1,2} \d{1,2}\.\d{1,2}\.\d{4}$/;
    return pattern.test(input);
  }

  closeSidebar() {
    AppStore.sidebarActive$.next(false);
  }

  triggerColorPicker(colorInput: HTMLInputElement): void {
    if (!colorInput.disabled) {
      colorInput.click();
    }
  }

  onBrainNameInputFocusIn() {
    if (isMobileInterfaceDefined()) {
      getMobileappLeftSidebarInterface().setKeyboardOverlay();
    }
  }

  onBrainNameInputFocusOut() {
    if (isMobileInterfaceDefined()) {
      getMobileappLeftSidebarInterface().setKeyboardDefault();
    }
  }

  isTruncated(element: HTMLElement): boolean {
    if (!element) {
      return false;
    }
    return element.scrollWidth > element.offsetWidth;
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  protected readonly getCurrentYear = getCurrentYear;
  protected readonly MyBrainPlans = MyBrainPlans;
  protected readonly isRegularUser = isRegularUser;
  protected readonly redirectToAffiliates = redirectToAffiliates;
}
